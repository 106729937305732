


body {
  margin: 0;
  padding: 0;
  font-family: 'Indie Flower', cursive;
font-family: 'Rubik', sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Ubuntu:wght@300;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* color: #fff; */
    /* font-family: 'Ubuntu', sans-serif; */
}

.navbar {
    background-color: rgb(22, 7, 36);
    display: flex;
    justify-content: space-around;
    align-items: center;
    line-height: 2rem;
}



.left h1 {
    font-size: 2rem;
    cursor: pointer;
    
    
}

.checkBtn {
    display: none;
}

#check {
    display: none;
}

.checkBtn {
    cursor: pointer;
    font-size: 30px;
    float: right;
    color: #ed1e79;
    line-height: 80px;
}

.right ul {
    display: flex;
    list-style: none;
}

.right ul li a {
    padding: 10px 20px;
    font-size: 1.2rem;
    color: white;
    cursor: pointer;
    text-decoration: none;
    transition: all 1s;
}

.right ul li a:hover {
    background-color: #fff;
    border-radius: 7px;
    color: rgb(22, 7, 36);
}

@media screen and (max-width:805px) {
    .list {
        width: 100%;
        height: 100vh;
        background-color: rgb(22, 7, 36);
        text-align: center;
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 4rem;
        left: 100%;
        transition: all 1s;
    }

    #check {
        display: none;
    }

    .checkBtn {
        display: block;
    }

    #check:checked~ul {
        left: 0%;
    }

}
/* .nav {
    width: 100%;
    height: 40px;
    position: fixed;
    line-height: 20px;
    text-align: center;
    background-color: ghostwhite;
    border-radius: 10px;
}

.nav div.logo {
    float: left;
    width: auto;
    height: auto;
    padding-top: 0.5rem;
    padding-left: 3rem;
}

.nav div.logo a {
    text-decoration: none;
    color: black;
    font-size: 2rem;
}

.nav div.logo a:hover {
    color: #00E676;
}

.bhen{
  color: black;
   font-family: 'Indie Flower', cursive;
font-family: 'Rubik', sans-serif;
font-size: 10px;
background-color: ghostwhite;
padding-left: 3px;
padding-right: 3px;
border-radius: 2px;
}

.nav div.main_list {
    height: 40px;
    float: right;
}

.nav div.main_list ul {
    width: 100%;
    height: 40px;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.nav div.main_list ul li {
    width: auto;
    height: 40px;
    padding: 0;
    padding-right: 3rem;
}

.nav div.main_list ul li a {
    text-decoration: none;
    color: black;
    line-height: 40px;
    font-size: 2rem;
}

.nav div.main_list ul li a:hover {
    color: #00E676;
} */


                          /* nav bar ends */

.helix {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  width: 100%;
  background-image: linear-gradient(rgba(0,0,0,.4), rgba(0,0,0,.4)), url(https://raw.githubusercontent.com/hudsonmarinho/header-and-footer-parallax-effect/master/assets/images/bg-header.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
  font-size: 5.5rem;
}

 /* .about1 {
    display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  width: 100%;
   background-image: linear-gradient(rgba(0,0,0,.4), rgba(0,0,0,.4)), url(https://images.unsplash.com/photo-1549880181-56a44cf4a9a5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80); 
   background-color: black; 
   background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: #fff; 
   font-size: 90px; 
}  */

.ululu{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  width: 90%;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
  /* font-size: 5.5rem; */
}
.lulu{
  display: flexbox;
  background-color:black;
  height: 70vh;
  width: 100vw;
  flex-direction: column;
}
.mulu{
  display: inline-block;
  background-color:black;
  height: 10vh;
  width: 30vw;
  padding: 1rem 1rem;
  text-align: center;
  vertical-align: middle;
}
.age{
  
  display: inline-block;
  /* background-image: url('https://d14u0p1qkech25.cloudfront.net/club_308016172_61e4e7c7-6516-40b7-a0ab-f3a6b8126233_thumbnail_250x250'); */
  background-color: black;
  height: 40vh;
  width: 70vh;
  margin-top: 20vh;
  margin-bottom: 10vh;
  margin-right: 20px;
  margin-left: 10vw;
  font-style: italic;
  font-size:20px;
  /* flex-direction: column; */
  
  
}
.titu{
  align-items: left;
  display: flexbox;
  background-color: grey;
  height: 50vh;
  width: 40vh;
  margin-top: 10vh;
  margin-bottom: auto;
  margin-right: 10vw;
  margin-left: 20px;

}

.about1 {
  display: flexbox;
justify-content: left;
align-items: left;
text-align: right;
height: 100vh;
width: 100%;
/* background-image: linear-gradient(rgba(0,0,0,.4), rgba(0,0,0,.4)), url(https://images.unsplash.com/photo-1549880181-56a44cf4a9a5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80); */
/* background-color: black; */
/* background-repeat: no-repeat; */
background-size: cover;
background-attachment: fixed;
color: black;
font-size: 20px;
margin-top: 10vh;
}



section.card {
  background: #f6f6f6;
  border-radius: 24px 24px 24px 48px;
  padding: 48px 48px 48px 308px;
  margin: 84px 0;
  margin-top: 10vh;
  width: 60vw;
  /* max-width: 920px;
  min-width: 640px; */
  display: block;
  width: 40vw;
  /* display: flex; */
  /* flex-direction: row; */
  /* align-self: flex-start; */
  position: relative;
  box-shadow: 5px 0 0 0 rgba(204, 204, 204, 0.3), -5px 0 0 0 rgba(204, 204, 204, 0.3), 0 5px 0 0 rgba(204, 204, 204, 0.3);
}
section.card .text-content {
  
 display: block;
  flex-direction: column;
  /* width: calc(60vw - 192px); */
  max-width: 60vw;
}
section.card .text-content h3 {
  margin: 0;
  font-size: 1.5em;
  max-width: 20ch;
}
section.card .text-content p {
  margin: 16px 0 24px;
  max-width: 36ch;
}
section.card .text-content a {
  border: 3px solid #000;
  color: #000;
  align-self: flex-start;
  text-decoration: none;
  padding: 16px 32px;
  border-radius: 12px;
  transition: 400ms all;
}
section.card .text-content a:hover {
  background: #000;
  color: white;
}
section.card .visual {
  width: 240px;
  height: 100%;
  position: absolute;
  top: -24px;
  left: 24px;
  overflow: hidden;
  border-radius: 24px;
  box-shadow: 1px 2px 6px rgba(255, 255, 255, 0.25), 2px 6px 12px rgba(0, 0, 0, 0.25);
}
section.card .visual img {
  height: 110%;
}
section.card:nth-of-type(odd) {
  margin: 0 0 0 248px;
  border-radius: 24px 24px 48px 24px;
  padding: 48px 308px 48px 60px;
}
section.card:nth-of-type(odd) .visual {
  right: 24px;
  left: auto;
}
section.card:nth-of-type(odd) .text-content a {
  align-self: flex-start;
}
article {
  margin: auto;
  display: block;
  width: calc(60vw + 248px);
  max-width: calc(920px + 248px);
  min-width: calc(640px + 248px);
}
/* body {
  margin: 0;
  padding: 96px;
  font-size: 21px;
  background: #fff;
  color: #000;
  font-family: "gelica", sans-serif;
  font-weight: 400;
  font-style: normal;
} */
@media all and (max-width: 1100px) {
  /* body {
    padding: 96px 48px 24px;
  } */
  article {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  section.card {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  section.card .text-content {
    width: 100%;
  }
  section.card:nth-of-type(odd) {
    margin: 0;
  }
}
@media all and (max-width: 720px) {
  /* body {
    padding: 48px 24px 0;
  } */
  section.card {
    margin: 64px 0;
    padding: 88px 36px 36px;
    flex-direction: column;
    border-radius: 24px;
  }
  section.card:nth-of-type(odd) {
    padding: 88px 36px 36px;
    border-radius: 24px;
  }
  section.card .text-content h3 {
    max-width: calc(100% - 150px);
    font-size: 1em;
  }
  section.card .text-content p,
  section.card .text-content a {
    font-size: 0.8em;
  }
  section.card .visual,
  section.card:nth-of-type(odd) .visual {
    position: relative;
    width: 150px;
    height: 150px;
    right: 16px;
    left: auto;
    
  }
  section.card .visual img,
  section.card:nth-of-type(odd) .visual img {
    position: absolute;
    width: 100%;
    height: auto;
   
  
  }
}
/* html {
  box-sizing: border-box;
} */
*,
*:before,
*:after {
  box-sizing: inherit;
}

:root{
  --white:#FFF;
  --black:#000;
  --dark:#1E1E1E;
  --gray:rgba(1,1,1,0.6);
  --lite:rgba(255,255,255,0.6);
  --primary:#002347;
  --secondary:#fdc632;
}


/***************************
          DEFAULT
****************************/
body{
  margin:0;
  overflow-x:hidden !important;
  font-family: 'Poppins', sans-serif;
}

a{
  text-decoration:none !important;
  min-width: fit-content;
  width: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
}

a, button{
  transition:0.5s;
}

a, p{
  font-size:14px;
}

h1, h2, h3, h4, h5, h6{
  color:var(--primary);
  font-weight:600;
}

a, button, input, textarea, select{
  outline:none !important;
}

fieldset{
  border:0;
}

.title{
  color:var(--primary);
}

.flex, .fixed_flex{
  display:flex;
}

.flex-content{
  width:100%;
  position:relative;
}

.padding_1x{
  padding:1rem;
}

.padding_2x{
  padding:2rem;
}

.padding_3x{
  padding:3rem;
}

.padding_4x{
  padding:4rem;
}

.btn{
  padding:0.8rem 2rem;
  border-radius:5px;
  text-align:center;
  font-weight:500;
  text-transform:uppercase;
}

.btn_1{
  border:1px solid var(--primary);
  background-color:var(--primary);
  color:var(--secondary);
}

.btn_1:hover{
  background-color:transparent;
  color:var(--primary);
}

.btn_2{
  border:1px solid var(--secondary);
  background-color:var(--secondary);
  color:var(--primary);
}

.btn_2:hover{
  border:1px solid var(--primary);
  background-color:var(--primary);
  color:var(--secondary);
}

@media (max-width:920px){
  .flex{
      flex-wrap:wrap;
  }
  
  .padding_1x, .padding_2x, .padding_3x, .padding_4x{
      padding:1rem;
  }

  .btn{
      padding:0.5rem 1rem;
  }
  
  a, p{
      font-size:12px;
  }
}



/***************************
             FOOTER
****************************/
footer{
  background-color:var(--primary);
  color:var(--lite);
}

footer h3{
  color:var(--white);
  margin-bottom:1.5rem;
}

footer a{
  color:var(--lite);
  display:block;
  margin:15px 0;
}

footer a:hover{
  color:var(--white);
}

footer fieldset{
  padding:0;
}

footer fieldset input{
  background-color:#334f6c;
  border:0;
  color:var(--lite);
  padding:1rem;
}

footer fieldset .btn{
  border-radius:0;
  border:0;
}

footer fieldset .btn_2:hover{
  background-color:var(--secondary);
  border:0;
  color:var(--primary);
}

footer .flex:last-child{
  align-items:center;
}

footer .flex:last-child .flex-content:last-child{
  text-align:right;
}

footer .flex:last-child p{
  color:var(--white);
}

footer .flex:last-child a{
  width:40px;
  display:inline-block;
  background-color:#334f6c;
  color:var(--white);
  padding:0.5rem;
  margin-right:3px;
  text-align:center;
}

footer .flex:last-child a:hover{
  background-color:var(--secondary);
  color:var(--gray);
}

@media (max-width:1100px){
  footer .flex:first-child{
      flex-wrap:wrap;
  }
  
  footer .flex:first-child .flex-content{
      flex: 1 1 40%;
  }
}

@media (max-width:920px){
  footer .flex:last-child .flex-content:last-child{
      text-align:left;
  }
}

@media (max-width:320px){
  footer .flex:first-child .flex-content{
      flex:1 1 100%;
  }
}

